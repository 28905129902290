<template>
  <header class="header">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light">
        <router-link class="header-logo" to="/">NeatFusion</router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#menuToggler" aria-controls="menuToggler" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse text-center" id="menuToggler">
          <ul class="header-content navbar-nav flex-grow-1 px-lg-4">
            <li class="nav-item">
              <a href="#projects">Projects</a>
            </li>
            <li class="nav-item">
              <a href="#technologies">Technologies</a>
            </li>
          </ul>
          <a class="header-button" href="#get-in-touch">Get in touch</a>
        </div>
      </nav>
    </div>
  </header>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class Header extends Vue {}
</script>

<style lang="scss" scoped>
.header-content {
  display: flex;
  margin: 0 auto;
}

.header-logo {
  font-family: 'Fredoka One';
  font-size: 16pt;
}

.header-content a {
  display: inline-block;
  font-weight: 700;
  padding: 10px 20px !important;
  text-transform: capitalize;
  color: #222 !important;
}

.header-content a.router-link-active, .header-content a:hover {
  color: #1565c0 !important;
}

.header-button {
  display: inline-block;
  padding: 14px 40px 12px;
  border-radius: 32px;
  line-height: 26px;
  font-weight: 700;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 1em;
  background-color: #1565c0;
  color: #fff;
  transition: background-color 200ms ease-out;
}

.header-button:hover {
  color: #fff;
  background-color: #2c74c6;
}
</style>