<template>
  <footer>
    <div class="footer-top py-5">
      <div class="container">
        <p class="m-0">
          NeatFusion team create high quality application solutions for Web, Android, iOS and Desktop platforms.
          With good understanding of business requirements, we deliver really <strong>neat</strong> products.
        </p>
      </div>
    </div>
    <div class="footer-bottom py-3 py-md-0">
      <div class="container footer-bottom-content">
        <nav class="nav flex-column flex-md-row">
          <a class="nav-link py-2 py-md-3" href="#projects">Projects</a>
          <a class="nav-link py-2 py-md-3" href="#technologies">Technologies</a>
          <a class="nav-link py-2 py-md-3" href="#get-in-touch">Get in touch</a>
        </nav>
        <router-link class="footer-logo" to="/">© NeatFusion</router-link>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.footer-top {
  color: #6a7785;
  background-color: #142333;
}

.footer-bottom {
  color: #6a7785;
  background-color: #202f40;
}

.footer-bottom .nav-link {
  color: #6a7785;
}

.footer-bottom .nav-link:hover {
  color: #88929d;
}

.footer-bottom-content {
  display: flex;
  justify-content: space-between;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.footer-logo {
  color: #6a7785;
  align-self: center;
  font-family: 'Fredoka One';
  font-size: 16pt;
}

.footer-logo:hover {
  color: #88929d;
}
</style>