import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Services from '../views/Services.vue'
import Experience from '../views/Experience.vue'
import Foundation from '../views/Foundation.vue'
import Team from '../views/Team.vue'
import Blog from '../views/Blog.vue'
import GetInTouch from '../views/GetInTouch.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/services',
    component: Services
  },
  {
    path: '/experience',
    component: Experience
  },
  {
    path: '/foundation',
    component: Foundation
  },
  {
    path: '/team',
    component: Team
  },
  {
    path: '/blog',
    component: Blog
  },
  {
    path: '/get-in-touch',
    component: GetInTouch
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
