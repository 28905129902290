<template>
  <div class="main mb-5">
    <div class="container">
      <div class="main-content">
        <h1 class="display-5 title">It’s hard to find good developers,<br>but you’re in the right place.</h1>
      </div>
    </div>
  </div>
  <div class="container" id="projects">
    <h1 class="title">Our projects</h1>
    <p>We work hard to deliver product with best quality possible.</p>
  </div>
  <div class="container mb-5">
    <div class="app-card text-white bg-main mb-4">
      <div class="card-body">
        <h3 class="card-title title">MoneyKeeper - Budget Manager</h3>
        <p class="card-text">MoneyKeeper - a mobile budget was created to give you the opportunity to keep track of personal finance anytime, anywhere and placed on your smartphone. MoneyKeeper was created simple, so that you have an alternative to very complex applications. You can easily use MoneyKeeper, even if you have never worked with budget applications before.</p>
        <a role="button" class="btn btn-light me-2" href="https://bookkeeper.crosscone.com">Web Site</a>
        <a role="button" class="btn btn-light me-2" href="https://apps.apple.com/app/apple-store/id1518456349?pt=120244518&ct=si&mt=8">App Store</a>
        <a role="button" class="btn btn-light" href="https://play.google.com/store/apps/details?id=com.crosscone.moneykeeper">Google Play</a>
      </div>
    </div>
    <div class="app-card text-white bg-main mb-4">
      <div class="card-body">
        <h3 class="card-title title">BookKeeper - Accounting</h3>
        <p class="card-text">BookKeeper - mobile commerce was created to give you the opportunity to conduct business anytime, anywhere and placed in your smartphone. BookKeeper was created simple, so that you have an alternative to very complex applications. You can easily use BookKeeper, even if you have never worked with accounting applications before.</p>
        <a role="button" class="btn btn-light me-2" href="https://moneykeeper.app">Web Site</a>
        <a role="button" class="btn btn-light" href="https://apps.apple.com/us/app/id1471516667">App Store</a>
      </div>
    </div>
  </div>
  <div class="container mb-5" id="technologies">
    <h1 class="title">Tech stack we use</h1>
    <p>We believe that choosing right tooling for specific business needs is vital for the success.</p>
    <h3>
      <span class="badge rounded-pill bg-main mb-2">Android</span>
      <span>&nbsp;</span>
      <span class="badge rounded-pill bg-main mb-2">iOS</span>
      <span>&nbsp;</span>
      <span class="badge rounded-pill bg-main mb-2">Flutter</span>
      <span>&nbsp;</span>
      <span class="badge rounded-pill bg-main mb-2">Golang</span>
      <span>&nbsp;</span>
      <span class="badge rounded-pill bg-main mb-2">Swift</span>
      <span>&nbsp;</span>
      <span class="badge rounded-pill bg-main mb-2">Java</span>
      <span>&nbsp;</span>
      <span class="badge rounded-pill bg-main mb-2">Node</span>
      <span>&nbsp;</span>
      <span class="badge rounded-pill bg-main mb-2">JS</span>
      <span>&nbsp;</span>
      <span class="badge rounded-pill bg-main mb-2">Vue</span>
      <span>&nbsp;</span>
      <span class="badge rounded-pill bg-main mb-2">Dart</span>
      <span>&nbsp;</span>
      <span class="badge rounded-pill bg-main mb-2">MongoDB</span>
      <span>&nbsp;</span>
      <span class="badge rounded-pill bg-main mb-2">RabbitMQ</span>
      <span>&nbsp;</span>
      <span class="badge rounded-pill bg-main mb-2">Kubernetes</span>
      <span>&nbsp;</span>
      <span class="badge rounded-pill bg-main mb-2">Helm</span>
      <span>&nbsp;</span>
      <span class="badge rounded-pill bg-main mb-2">gRPC</span>
    </h3>
  </div>
  <div class="main" id="get-in-touch">
    <div class="container">
      <div class="py-5">
        <h1 class="text-center title">Get in touch</h1>
        <p class="mb-5">We’ll contact you within a few hours with our next steps. We normally schedule a call with our engineers to discuss your project in more detail. If you’d like to sign an NDA, please let us know. We’ll prepare it for you.</p>
        <form>
          <div class="mb-3">
            <input type="text" placeholder="Name" class="form-control" id="name">
          </div>
          <div class="mb-3">
            <input type="email" placeholder="Email" class="form-control" id="email">
          </div>
          <div class="mb-3">
            <input type="tel" placeholder="Phone number" class="form-control" id="phone">
          </div>
          <div class="mb-4">
            <textarea name="message" placeholder="Message" class="form-control" id="message" rows="4"></textarea>
          </div>
          <a href="/" class="message-link">Send</a>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class Home extends Vue {}
</script>

<style lang="scss" scoped>
.form-control {
  padding: 16px;
  font-size: 14pt;
  font-weight: 700;
  color: #fff;
  border-width: 0px;
  border-radius: 16px;
  background-color: #11519a;
}

.form-control::placeholder {
  color: #4484cd;
}

.message-link {
  padding: 16px 64px;
  display: inline-block;
  background-color: #fff;
  border-radius: 32px;
  font-weight: 700;
  text-transform: uppercase;
}
</style>