<template>
    <Header></Header>
    <main>
        <router-view/>
    </main>
    <Footer></Footer>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

@Options({
  components: {
    Header,
    Footer,
  },
})

export default class App extends Vue {}
</script>

<style lang="scss">
body {
  font-family: 'Nunito';
}

p, a {
  font-size: 14pt;
}

a {
  text-decoration: none;
  color: #135bad;
}

a:hover {
  color: #2c74c6;
}

.title {
  font-weight: 700;
}

.app-card {
  padding: 15px 20px;
  border-radius: 1rem;
}

.bg-main {
  background-color: #1565c0;
}

.main {
  background-color: #1565c0;
  color: #fff;
}

.main-content {
  padding: 120px 0;
}
</style>
