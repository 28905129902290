<template>
  <div class="main mb-5">
    <div class="container">
      <div class="main-content">
        <h1 class="display-5 title">We provide Web, Mobile and Desktop<br>development services.</h1>
      </div>
    </div>
  </div>
  <div class="container mb-5">
    <h1 class="title">Experience</h1>
    <p>Coming soon...</p>
  </div>
</template>