<template>
  <div class="main">
    <div class="container">
      <div class="py-5">
        <h1 class="text-center title">Get in touch</h1>
        <p class="mb-5">We’ll contact you within a few hours with our next steps. We normally schedule a call with our engineers to discuss your project in more detail. If you’d like to sign an NDA, please let us know. We’ll prepare it for you.</p>
        <form>
          <div class="mb-3">
            <input type="text" placeholder="Name" class="form-control" id="name">
          </div>
          <div class="mb-3">
            <input type="email" placeholder="Email" class="form-control" id="email">
          </div>
          <div class="mb-3">
            <input type="tel" placeholder="Phone number" class="form-control" id="phone">
          </div>
          <div class="mb-4">
            <textarea name="message" placeholder="Message" class="form-control" id="message" rows="4"></textarea>
          </div>
          <a href="/get-in-touch" class="message-link">Send</a>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-control {
  padding: 16px;
  font-size: 14pt;
  font-weight: 700;
  color: #fff;
  border-width: 0px;
  border-radius: 16px;
  background-color: #11519a;
}

.form-control::placeholder {
  color: #4484cd;
}

.message-link {
  padding: 16px 64px;
  display: inline-block;
  background-color: #fff;
  border-radius: 32px;
  font-weight: 700;
  text-transform: uppercase;
}
</style>